const TESTCOMP = '/testComp';
const LOGIN = '/login';
const MAIN = '/main';
const SIGNUP = '/signup';
const BETADINE = '/betadine';
const TACHOSIL = '/tachosil';
const MERONEM = '/meronem';
const SEROQUEL = '/seroquel';
const FERINJECT = "/ferinject"
const CONTACTUS = '/contactus';


// const TODAY = '/today';
// const CAREPLAN = '/careplan';
// const CAREPLANDETAIL = 'carePlanDetail';
// const CAREPLANREPORT = 'carePlanReport';
// const CAREPLANREPORTW = 'carePlanReportW';
// const GUIDE = '/guide';
// const GUIDEDETAIL = '/guideDetail';
// const MANAGEMENT = '/manage';
// const CARENOTE = '/carenote';
// const QUESTION = '/question';
// const ONBOARD = '/onboard';
// const PREVIEW = '/preview';
// const CPQUERESULT = '/qeuResult';
// const BLOODPRESSURE = '/bloodPressure';
// const BLOODGLUCOSE = '/bloodGlucose';
// const WEIGHT = 'weight';
// const NUTRITION = '/nutrition';
// const NUTRITIONSEARCH = '/nutritionSearch';
// const NUTRITIONDETAIL = '/nutritionDetail';
// const MEDICATION = '/medication';
// const EXERCISE = '/exercise';
// const ACCOUNT = '/account';
// const PROFILE = '/profile';
// const DEVICE = '/device';
// const NOTICE = '/notice';
// const NOTICEDETAIL = 'noticeDetail'
// const SETTING = '/setting';
// const SETTINGALARM = 'settingAlarm';
// const TESTCHART = '/testChart';

export {
    TESTCOMP,
    LOGIN,
    MAIN,
    SIGNUP,
    BETADINE,
    TACHOSIL,
    MERONEM,
    SEROQUEL,
    FERINJECT,
    CONTACTUS,
    // TODAY,
    // CAREPLAN,
    // CAREPLANDETAIL,
    // CAREPLANREPORT,
    // CAREPLANREPORTW,
    // GUIDE,
    // GUIDEDETAIL,
    // MANAGEMENT,
    // CARENOTE,
    // QUESTION,
    // ONBOARD,
    // PREVIEW,
    // CPQUERESULT,
    // BLOODPRESSURE,
    // BLOODGLUCOSE,
    // WEIGHT,
    // NUTRITION,
    // NUTRITIONSEARCH,
    // NUTRITIONDETAIL,
    // MEDICATION,
    // EXERCISE,
    // ACCOUNT,
    // PROFILE,
    // DEVICE,
    // NOTICE,
    // NOTICEDETAIL,
    // SETTING,
    // SETTINGALARM,
    // TESTCOMP,
    // TESTCHART,
}