import Vue from 'vue';
import Vuetify from "vuetify";
// import Antd from 'ant-design-vue';
import App from './App.vue';
import router from '@/routes/index';
import store from '@/store/index';
import VueMoment from 'vue-moment';
import moment from 'moment';
moment.locale('ko');
import i18n from '@/libs/i18n';
// import 'ant-design-vue/dist/antd.css';
import "vuetify/dist/vuetify.min.css";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueCookies from 'vue-cookies';
import * as url_lists from '@/libs/url_lists';
import * as helpers from '@/utils/helper';
import * as changeValue from '@/utils/changeValue';
import SmoothPicker from 'vue-smooth-picker';

export const eventBus = new Vue();

Vue.prototype.$url_list = url_lists;
Vue.prototype.$helper = helpers;
Vue.prototype.$changeValue = changeValue;

Vue.config.productionTip = false;
// Vue.use(Antd);
Vue.use(VueToast);
Vue.use(VueCookies);
Vue.use(VueMoment, { moment });
Vue.use(SmoothPicker);
Vue.use(Vuetify);

/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  vuetify: new Vuetify(),
}).$mount('#app');
