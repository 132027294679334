export function getSrmColor(color) {
    return color;
}

// 경로확인
// export function urlSelector(path) {
//     let retVal = '';
//     if (path.indexOf('today') > -1) {
//         retVal = 'today';
//     }
//     if (path.indexOf('careplan') > -1) {
//         retVal = 'careplan';
//     }
//     if (path.indexOf('guide') > -1) {
//         retVal = 'guide';
//     }
//     if (path.indexOf('manage') > -1) {
//         retVal = 'manage';
//     }
//     if (path.indexOf('careNote') > -1) {
//         retVal = 'carenote';
//     }
//     return retVal;
// }

/** gruop array : [{name:'사과',...},{name:'사과',...}]
   * 같은 value값으로 배열을 묶어준다.
   */
export const groupBy = (array, f) => {
    const groups = {};
    array.forEach(function (o) {
        const group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
        return groups[group];
    });
}

/** 00:00표기 */
export const format_dp_time = (gapT) => {
    let temp = String(Math.floor(gapT / 60)); // 부분 두자리 계산 mm
    if (Math.floor(gapT / 60) < 10) {
        temp = "0" + temp;
    }

    temp = temp + ":"; // mm:ss의 : 이 부분추가 //초부분 두자리 계산 ss
    if (gapT % 60 < 10) {
        temp = temp + "0";
    }

    temp = temp + (gapT % 60);
    return temp;
}

export const validateEmail = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// HKA 테이블 profession 컬럼 이름 변경
export const changePFcolumnName = (columnName) => {
    let result = '';
    switch (columnName) {
        case 'doc':
            result = 'Doctor';
            break;
        case 'surg':
            result = 'Surgeon';
            break;
        case 'dent':
            result = 'Dentist';
            break;
        case 'nur':
            result = 'Nurse';
            break;
        case 'radio':
            result = 'Radiographer';
            break;
        case 'manager':
            result = 'Hospital Procurement Manager';
            break;
    }
    return result;
}
// HKA 테이블 department 컬럼 이름 변경
export const changeDPcolumnName = (columnName) => {
    let result = '';
    switch (columnName) {
        case 'AE':
            result = 'Accident & Emergency';
            break;
        case 'AICU':
            result = 'Adult Intensive Care Unit';
            break;
        case 'ana':
            result = 'Anaesthesiology';
            break;
        case 'CS':
            result = 'Cardiothoracic Surgery';
            break;
        case 'CO':
            result = 'Clinical Oncology';
            break;
        case 'ENT':
            result = 'Ear, Nose & Throat';
            break;
        case 'end':
            result = 'Endocrinology';
            break;
        case 'FMPH':
            result = 'Family Medicine & Primary Healthcare';
            break;
        case 'gas':
            result = 'Gastroenterology';
            break;
        case 'hem':
            result = 'Hematology';
            break;
        case 'ID':
            result = 'Infectious Disease';
            break;
        case 'med':
            result = 'Medicine';
            break;
        case 'mic':
            result = 'Microbiology';
            break;
        case 'neu':
            result = 'Neurosurgery';
            break;
        case 'OG':
            result = 'Obstetrics & Gynaecology';
            break;
        case 'oph':
            result = 'Ophthalmology';
            break;
        case 'OMSDS':
            result = 'Oral Maxillofacial Surgery & Dental Surgery';
            break;
        case 'OT':
            result = 'Orthopaedics & Traumatology';
            break;
        case 'PC':
            result = 'Paediatric Cardiology';
            break;
        case 'PAM':
            result = 'Paediatrics & Adolescent Medicine';
            break;
        case 'pat':
            result = 'Pathology';
            break;
        case 'psy':
            result = 'Psychiatry';
            break;
        case 'res':
            result = 'Respiratory';
            break;
        case 'sur':
            result = 'Surgery';
            break;
    }
    return result;
}

// 요일
export const weekLal = (week) => {
    let result = '';
    switch (week) {
        case 0:
            result = '일요일';
            break;
        case 1:
            result = '월요일';
            break;
        case 2:
            result = '화요일';
            break;
        case 3:
            result = '수요일';
            break;
        case 4:
            result = '목요일';
            break;
        case 5:
            result = '금요일';
            break;
        case 6:
            result = '토요일';
            break;
    }
    return result;
}

// am pm
export const amPmLal = (ampm) => {
    let result = '';
    if (ampm < 12) {
        result = '오전'
    } else {
        result = '오후'
    }
    return result;
}
