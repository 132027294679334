const state = {
//   sidebarToggleProperties: {
//     isSideNavOpen: true,
//     isSecondarySideNavOpen: false,
//     isActiveSecondarySideNav: false,
//   },
}

const getters = {
//   getSideBarToggleProperties: state => state.sidebarToggleProperties,
}

const actions = {
//   changeSidebarProperties ({ commit }) {
//     commit('toggleSidebarProperties')
//   },
}

const mutations = {
//   toggleSidebarProperties: state =>
//     (state.sidebarToggleProperties.isSideNavOpen = !state
//       .sidebarToggleProperties.isSideNavOpen),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
