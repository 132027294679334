import { getUserNameFromCookie, saveUserNameToCookie } from '@/utils/cookies';
import { loginUser } from '@/api/auth'
import Vue from 'vue';
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

const state = {
    // 쿠키로 항상 로그인 유지 처리
    userData: getUserNameFromCookie() !== '' ? JSON.parse(getUserNameFromCookie()) || '' : null,
    userName: getUserNameFromCookie() !== '' ? JSON.parse(getUserNameFromCookie()).userName || '' : null,
    Idx: getUserNameFromCookie() !== '' ? JSON.parse(getUserNameFromCookie()).Idx || '' : null,
}

const getters = {
    distInfo() {
        return {
            distDay: '2021.06.10',
            appVersion: '1.0.0.0',
        }
    },
    isLogin: (state) => state.userName !== '' && state.userName !== null && state.userName !== undefined,
    readIdx: (state) => state.Idx,
    ReadUserData: (state) => state.userData,
}

const actions = {
    async logIn({ commit }, {userData, rememberMe}) {
        const data  = await loginUser(userData);
        const R = data;
        if (R.return_code === '100') {
            commit('setUserName', R.userName);
            commit('setUserIdx', R.Idx);
            commit('setUserData', R);
            saveUserNameToCookie(JSON.stringify({
                userName : R.userName,
                Idx: R.Idx,
                email: R.email,
                profession: R.profession,
                professionOthers: R.professionOthers,
                department: R.department,
                departmentOthers: R.departmentOthers,
            }));
            // remember me 체크 박스 확인, 체크되어 있으면 로컬스토리지 저장 후 로그인 처리 -> 로컬스토리지에 저장
            if (rememberMe) {
                localStorage.setItem("userId", userData.name);
            } else {
            // 로컬스토리지 삭제
                localStorage.removeItem("userId");
            }
        } else if (R.return_code === '901' || R.return_code === '902'){
            Vue.$toast.open({
              message: R.msg,
              type: 'default',
              duration: 3000,
              dismissible: true,
            });
            return;
        } else {
            Vue.$toast.open({
                message: R.msg,
                type: "error",
                duration: 3000,
                dismissible: true
            })
            return;
        }
    },
    async logOut({ commit }, userData) {
        // const { data } = await logOutUser(userData);
        // const R = data.result;
        // // console.log(R);

        // // 성공시만 로컬정보 삭제
        // if (R.code !== '100') {
        //     Vue.$toast.open({
        //         message: '로그아웃 실패~',
        //         type: "error",
        //         duration: 3000,
        //         dismissible: true
        //     })
        //     return
        // }
        Vue.$cookies.remove("HK_userName", { path: "/" });
        commit('clearUserName');
        commit('clearUserIdx');
        commit('clearUserData');
        // commit('clearToken');
        // deleteCookie('ota_auth');
        // deleteCookie('ota_user');
    }
}

const mutations = {
    setUserName(state, userName) {
        state.userName = userName;
    },
    clearUserName(state) {
        state.userName = null;
    },
    setUserIdx(state, Idx) {
        state.Idx = Idx;
    },
    clearUserIdx(state) {
        state.Idx = null;
    },
    setUserData(state, userData) {
        state.userData = {
            userName : userData.userName,
            Idx: userData.Idx,
            email: userData.email,
            profession: userData.profession,
            professionOthers: userData.professionOthers,
            department: userData.department,
            departmentOthers: userData.departmentOthers,
        };
    },
    clearUserData(state) {
        state.userData = null;
    },
    setUsermobile(state, username) {
        state.usermobile = username;
    },
    clearUsermobile(state) {
        state.usermobile = '';
    },
    setToken(state, token) {
        state.token = token;
    },
    clearToken(state) {
        state.token = '';
    },
    // 상태저장(복약정보 일자저장)
    setMedicationDate(state, date) {
        state.medicationDate = date;
    },
    setNutritionDate(state, date) {
        state.nutritionDate = date;
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
