// function saveAuthToCookie(value) {
//   document.cookie = `AA_auth=${value}`;
// }

function saveUserNameToCookie(value) {
  document.cookie = `HK_userName=${value}`;
}

// function getAuthFromCookie() {
//   return document.cookie.replace(
//     /(?:(?:^|.*;\s*)AA_auth\s*=\s*([^;]*).*$)|^.*$/,
//     '$1',
//   );
// }

function getUserNameFromCookie() {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)HK_userName\s*=\s*([^;]*).*$)|^.*$/,
    '$1',
  );
}

function deleteCookie(value) {
  document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;
}

export {
  // saveAuthToCookie,
  saveUserNameToCookie,
  // getAuthFromCookie,
  getUserNameFromCookie,
  deleteCookie,
};
