import Vuex from 'vuex'
import Vue from 'vue'
import sample from './modules/sample'
import authData from './modules/authData'
import themeConfig from './modules/themeConfig'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    sample,
    authData,
    themeConfig,
  },
})
