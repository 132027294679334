export const mealTypeToLbl = (type) => {
  let result = '';
  switch (type) {
    case 0:
      result = 'Breakfast';
      break;
    case 1:
      result = 'Morning Snack';
      break;
    case 2:
      result = 'Lunch';
      break;
    case 3:
      result = 'Afternoon Snack';
      break;
    case 4:
      result = 'Dinner';
      break;
    case 5:
      result = 'Midnight Snack';
      break;
  }
  return result;
}

export const medicationTypeToLbl = (type) => {
  let result = '';
  switch (type) {
    case 0:
      result = 'Breakfast';
      break;
    case 1:
      result = 'Lunch';
      break;
    case 2:
      result = 'Dinner';
      break;
  }
  return result;
}