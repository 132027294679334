<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import { deleteCookie } from '@/utils/cookies';
export default {
  name: 'JungnangSafeScreen',
  metaInfo() {
    return {
      title: 'Jungnang Safe Screen',
    };
  },
  computed: {
    // isUserLogin() {
    //   return this.$store.getters.isLogin; // && this.$store.state.token !== undefined;
    // },
  },
  created() {
    this.initLocale();
    // 네트워크 재연결되었을때 브라우저 새로고침
    window.addEventListener('online', ()=>{ this.$router.go()});
  },
  methods: {
    initLocale() {
      const cookieLocale = this.$cookies.get('gch_user_locale');
      console.log(cookieLocale);
      if (cookieLocale === null || cookieLocale === undefined || cookieLocale === '') {
        let locale = navigator.language || navigator.userLanguage;
        locale = locale.substring(0, 2);
        if (locale !== 'en') locale = 'ko';
        this.$i18n.locale = locale;
      } else {
        this.$i18n.locale = cookieLocale;
      }
      this.$moment().locale(this.$i18n.locale);
    },
    getRouterParams() {
      console.log(this.$router.currentRoute.query);
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/style';
@import 'assets/scss/layout';
@import 'assets/scss/common';
.app {
  height: 100%;
  // background-color: $page-background-color;
}
// .fade-enter {
//   opacity: 0;
// }

// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s ease-out;
// }

// .fade-leave-to {
//   opacity: 0;
// }

// .slide-fade-enter {
//   transform: translateX(10px);
//   opacity: 0;
// }

// .slide-fade-enter-active,
// .slide-fade-leave-active {
//   transition: all 0.2s ease;
// }

// .slide-fade-leave-to {
//   transform: translateX(-10px);
//   opacity: 0;
// }
</style>
