import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import * as URL_LISTS from '@/libs/url_lists';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  // 레이아웃에 따른 로드 페이지 처리 
  routes: [
    {
      path: '/',
      // 사이드, 탑바 없이 풀 페이지
      component: () => import('@/components/layouts/full/LayoutFull.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/views/MainPage.vue'),
        },

        {
          path: '*',
          component: () => import('@/views/NotFoundPage.vue')
        },
      ]
    },
    {
      path: '/',
      // 사이드, 텝바가 있는 페이지
      component: () => import('@/components/layouts/vertical/LayoutVertical'),
      children: [
        // {
        //   path: URL_LISTS.MAIN,
        //   component: () => import('@/views/today/TodayPage'),
        //   meta: { auth: true } // 인증이 필요한 경우
        // },
      ]
    }
  ]
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.auth && !store.getters.isLogin) {
//     console.log('인증이 필요합니다');
//     next('/login');
//     return;
//   }
//   next();
// });

export default router;
