// 로그인, 회원 가입, (ex) 회원 탈퇴
import { instance, logout, fileupload, login, signup } from './index';

const reqData = {
  data: {}
}

// 회원가입 API
function signUp(userData) {
  reqData.data = userData;
  return signup.post('', reqData)
  .then(res => {
    return res.data[0][0];
  })
  .catch(err => {
    return err;
  });
}

// 로그인 API
function loginUser(userData) {
  reqData.data = userData;
  return login.post('', reqData)    
  .then(res => {
    return res.data[0][0];
  })
  .catch(err => {
    return err;
  });
}

// 로그아웃 API
function logOutUser(userData) {
  reqData.data = userData;
  return logout.post('', reqData);
}

// 파일업로드 API
function queryfileUpload(formData) {
  const config = {
    header: { 'content-type': 'multipart/form-data' },
  };
  // reqData.data = formData;
  return fileupload.post('image', formData, config);
}

export { signUp, loginUser, logOutUser, queryfileUpload };
